import React from 'react';
import { useTranslation } from "react-i18next";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {
  Header, ContentHeader, Title, TextButton, LogoName,
  Options,
  FeaturedText, Button, IconButton, InputLanguage,
} from './Header.styles';

export default ({ changeTheme }) => {
  const { i18n } = useTranslation('common');
  const changeLanguage = (newLng) => {
    i18n.changeLanguage(newLng);
  };

  const [language, setLanguage] = React.useState('en');

  const handleChange = (event) => {
    const newLng = event.target.value;
    changeLanguage(newLng);
    setLanguage(newLng);
  };

  return (
    <Header>
      <ContentHeader>
        <Button to={{ pathname: '/' }}>
          <Title>
            <LogoName>malu</LogoName><FeaturedText>.dev</FeaturedText>
          </Title>
        </Button>
        <Options>
          <InputLanguage>
            <Select
              value={language}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Language' }}
            >
              <MenuItem value={'pt'}>
                <IconButton className='icon-button-language' icon='language'></IconButton>
                <TextButton>Português</TextButton>
              </MenuItem>
              <MenuItem value={'en'}>
                <IconButton className='icon-button-language' icon='language'></IconButton>
                <TextButton>English</TextButton>
              </MenuItem>
            </Select>
          </InputLanguage>

          <IconButton icon='invert_colors' onClick={() => changeTheme()}></IconButton>
        </Options>
      </ContentHeader>
    </Header>
  )
};