import React, { Suspense, useState } from 'react';
import { Router, Route, Switch } from "react-router";

import { createBrowserHistory } from 'history';
import { useTranslation } from "react-i18next";

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Profile from './screens/profile/Profile';

import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, colorsPallete } from './config/theme';
import { GlobalStyles } from './config/global';

import { AppBackground } from './App.styles';

const history = createBrowserHistory();

const App = () => {
  const { t } = useTranslation('common');
  const [theme, setTheme] = useState('light');
  const [logoColor, setLogoColor] = useState(colorsPallete[0]);

  const toggleTheme = () =>
    (theme === 'light') ? setTheme('dark') : setTheme('light');

  const setCurrentColor = (theme) => ({
    ...theme,
    currentColor: logoColor || colorsPallete[0]
  });

  const setThemeObject = () =>
    (theme === 'light') ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={setCurrentColor(setThemeObject(theme))}>
      <GlobalStyles />
      <AppBackground>
        <Suspense fallback="loading">
          <Router basename='/' history={history}>
            <Header changeTheme={toggleTheme}></Header>
            <Switch>
              <Route path="/" exact render={(props) => (<Profile t={t} setLogoColor={setLogoColor} {...props} />)} />
            </Switch>
            <Footer t={t}></Footer>
          </Router>
        </Suspense>
      </AppBackground>
    </ThemeProvider>
  )
};

export default App;
