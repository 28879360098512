import styled from 'styled-components';

export const AppBackground = styled.div`
  background-image: url(images/bg-${({ theme }) => theme.value}.png);
  background-size: 200%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  min-height: 100%;

  @media(max-width: 880px) {
    background-size: auto 100%;
  }
`;

