import React, { Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { content } from '../../i18n/Model';
import {
  DetailsProfileContent, ListCapacities, ItemFeaturedList,
  InfoBlock, ItemDateTime
} from './DetailsProfile.styles';

const DetailsProfile = ({ t }) => (
  <DetailsProfileContent>
    <InfoBlock>
      <h1> {t('profile.title')} </h1>
      {content.profile.info && content.profile.info.map((item, indexInfo) => (
        <div key={indexInfo}>
          <Fragment>
            <h2> {t(`profile.info.${indexInfo}.subtitle`)}</h2>
            <ListCapacities>
              {item.list && item.list.map((i, index) => (
                <li key={index}> {t(`profile.info.${indexInfo}.list.${index}`)}</li>
              ))}
            </ListCapacities>
          </Fragment>
        </div>
      ))}
    </InfoBlock>

    <InfoBlock>
      <h1> {t('professional.title')} </h1>
      {content.professional.list && content.professional.list.map((item, index) => (
        <ListCapacities key={index}>
          <ItemFeaturedList> {t(`professional.list.${index}.office`)}</ItemFeaturedList>
          <ItemDateTime> {t(`professional.list.${index}.date`)}</ItemDateTime>
          <li> {t(`professional.list.${index}.place`)}</li>
          <li> {t(`professional.list.${index}.knowledge`)}</li>
        </ListCapacities>
      ))}
    </InfoBlock>

    <InfoBlock>
      <h1> {t('academic.title')} </h1>
      {content.academic.list && content.academic.list.map((item, index) => (
        <ListCapacities key={index}>
          <ItemFeaturedList> {t(`academic.list.${index}.course`)}</ItemFeaturedList>
          <ItemDateTime> {t(`academic.list.${index}.date`)}</ItemDateTime>
          <li> {t(`academic.list.${index}.place`)}</li>
        </ListCapacities>
      ))}
    </InfoBlock>

    <InfoBlock>
      <h1> {t('events.title')} </h1>
      {content.events.list && content.events.list.map((item, index) => (
        <ListCapacities key={index}>
          <ItemFeaturedList> {t(`events.list.${index}.course`)}</ItemFeaturedList>
          <ItemDateTime> {t(`events.list.${index}.date`)}</ItemDateTime>
          {t(`events.list.${index}.place`) && (<li> {t(`events.list.${index}.place`)}</li>)}
          {t(`events.list.${index}.description`) && (<li> {ReactHtmlParser(t(`events.list.${index}.description`))}</li>)}
        </ListCapacities>
      ))}
    </InfoBlock>

    <InfoBlock>
      <h1> {t('awards.title')} </h1>
      {content.awards.list && content.awards.list.map((item, index) => (
        <ListCapacities key={index}>
          <ItemFeaturedList> {t(`awards.list.${index}.award`)}</ItemFeaturedList>
          <ItemDateTime> {t(`awards.list.${index}.date`)}</ItemDateTime>
          <li> {t(`awards.list.${index}.competition`)}</li>
          {t(`awards.list.${index}.description`) && (<li> {ReactHtmlParser(t(`awards.list.${index}.description`))}</li>)}
        </ListCapacities>
      ))}
    </InfoBlock>
  </DetailsProfileContent>
);

export default DetailsProfile;